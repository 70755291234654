/* Base Styles */
body {
    margin: 0;
    font-family: 'Sora', Tahoma, 'Roboto', 'Oxygen', sans-serif; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2A2A2A; 
    overflow-x: hidden; 
}
/* Navbar Styles */
/* Navbar Toggle Button */
.navbar-toggler {
    border: none; 
    outline: none; 
    background-color: transparent; 
}
.dropdown-toggle::after {
    display: none !important; 
}
/* Navbar Styles end */
/* download buttons in home section start */
.store-button {
    background-color: #87F731; 
    color: #373737; 
    font-weight: bold; 
    border: none;
    padding: 10px 15px;
    transition: all 0.3s ease-in-out; /* Smooth transition */
}

.store-button:hover,
.store-button:active {
    color: #ffffff; 
    background-color: #6CBF1D; 
    transform: scale(1.05); /* Slight scaling animation */
}
.store-button img {
    width: 20px;
    margin-right: 8px;
}
/* download buttons end */

/* Feauture section */
.feature-image img {
    width: 80%;
    border-radius: 10px;
    transition: transform 0.3s ease;
}
.feature-image img:hover {
    transform: scale(1.20);
}
/* Feauture section end */

/* Help & Support Css */
.support-card {
    border: none;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.support-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0,0,0,0.2);
}

.support-icon {
    margin-bottom: 20px;
    animation: fadeIn 1s ease-in;
}

.support-title {
    color: #67CA1B;
    font-size: 20px;
    font-weight: bold;
}

.faq-accordion .faq-item {
    border: none;
    margin-bottom: 10px;
}

.faq-accordion .faq-item .rotate-icon {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Help & Support Css End  */

/* Choose your ride Effect */
/* Keyframe for driving animation from left to right (for right-facing cars) */
@keyframes driveFromLeft {
  0% {
    transform: translateX(-150%); /* Start off-screen left */
    opacity: 0;
  }
  50% {
    transform: translateX(0); /* Final position in the card */
    opacity: 1;
  }
  100% {
    transform: translateX(150%); /* Move out of screen to the right */
    opacity: 0;
  }
}

/* Keyframe for driving animation from right to left (for left-facing cars) */
@keyframes driveFromRight {
  0% {
    transform: translateX(150%); /* Start off-screen right */
    opacity: 0;
  }
  50% {
    transform: translateX(0); /* Final position in the card */
    opacity: 1;
  }
  100% {
    transform: translateX(-150%); /* Move out of screen to the left */
    opacity: 0;
  }
}

/* Apply specific animations to certain cards */
.card .card-img-top.sweef-ride {
  animation: driveFromLeft 10s linear infinite;
}

.card .card-img-top.family-fleet {
  animation: driveFromRight 10s linear infinite;
}

.card .card-img-top.eco-drive {
  animation: driveFromLeft 10s linear infinite;
}

/* Prevent overflow within the cards */
.card {
  overflow: hidden; /* Hide anything outside the card */
  position: relative; /* This allows the card images to stay within */
}

.card-body {
  padding: 15px;
  text-align: left;
}

/* Ensure the images fit inside the card properly */
.card-img-top {
  object-fit: cover;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Choose your ride  Effect  end*/

/* Animation for the Jumping Effect */
@keyframes jump {
  0% {
    transform: translateY(-150px); /* Start above the normal position */
    opacity: 0;
  }
  60% {
    transform: translateY(20px); /* Bounce a little bit after coming down */
    opacity: 1;
  }
  100% {
    transform: translateY(0); /* Final position (on the ground) */
  }
}

/* Animation for Shaking Effect */
@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}

/* Updated Animation for Smoke Effect */
@keyframes smoke {
  0% {
    transform: scale(1); /* Start small */
    opacity: 0.6; /* Semi-transparent */
  }
  100% {
    transform: scale(10); /* Expand larger */
    opacity: 0; /* Fade out completely */
  }
}

/* Applying all the animations to the taxi image */
.taxi-image {
  animation: jump 1s ease-out, shake 0.6s ease-in-out 1s infinite;
  position: relative;
  z-index: 1; /* Ensure the car image stays above the smoke */
  max-width: 100%; /* Prevent the car image from exceeding its container */
  object-fit: contain; /* Ensure the image maintains its aspect ratio */
}

/* Add the smoke effect below the car */
.taxi-smoke {
  position: relative;
  display: inline-block;
  width: 100%;
}

.taxi-smoke::after {
  content: "";
  position: absolute;
  bottom: -20px; /* Position it just below the car */
  left: 50%;
  transform: translateX(-50%); /* Center the smoke */
  width: 40px;
  height: 40px;
  background: radial-gradient(circle, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0));
  border-radius: 50%;
  animation: smoke 2s ease-out infinite; /* Smooth smoke animation */
}
/* Animation for the Jumping Effect end */
/* Rotate sticker of screen to the left */
@keyframes rotateSticker {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hover Zoom Effect */
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply slide-in animation and hover zoom effect */
.img-fluid {
  animation: slideIn 1s ease-out forwards; /* Slide-in animation */
  transition: transform 0.3s ease-in-out;  /* Smooth zoom transition */
}

.img-fluid:hover {
  animation: zoomIn 0.5s ease-in-out; /* Zoom on hover */
}


/* Apply continuous animation for cars coming from the right (for left-facing cars) */
.animate-left {
  animation: driveFromRight 3s ease-in-out infinite; /* Move right to left */
  animation-delay: 0s; /* Start immediately */
}

/* Apply continuous animation for cars coming from the left (for right-facing car) */
.animate-right {
  animation: driveFromLeft 3s ease-in-out infinite; /* Move left to right */
  animation-delay: 1s; /* Delay to start after the first car */
}

/* Animation class */
.fade-in {
    opacity: 0;
    transform: translateY(20px); /* Start from below */
    animation: fadeIn 3.10s forwards; /* Animation duration */
}

@keyframes fadeIn {
    to {
        opacity: 1;
        transform: translateY(0); /* Move to original position */
    }
}

/* End of Home Component CSS */
